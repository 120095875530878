/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from  '@material-ui/core/styles';
import { List, ListItem, Button, colors, Collapse, Link, Badge, IconButton, Tooltip, Avatar } from '@material-ui/core';
import { Home as HomeIcon,
ExpandLess,
ExpandMore,
PowerSettingsNew as LogoutIcon,
Drafts as LeaveIcon, AlarmOn as AttendanceIcon,
ArrowRight as MenuClose,
ArrowDropDown as MenuOpen,
AccountBalanceWallet as AttachMoneyIcon,
LocalShipping as LocalShippingIcon,
AccountCircle as ProfileIcon,
Assignment as ReportIcon,
Settings as SettingsIcon,
Dashboard, Redeem, GetApp,
DoneAll,BorderAll, PeopleAlt,VerifiedUser,
ContactPhone, AddShoppingCart, AccountTree, Storefront as StorefrontIcon,
BusinessCenter as BusinessCenterIcon,
EventNote as EventNoteIcon, AccountBalance as AccountBalanceIcon,
ListAlt as ListAltIcon,ShoppingCart as ShoppingCartIcon,
Assignment as AssignmentIcon, ShoppingBasket as ShoppingBasketIcon, CastConnected as CastConnectedIcon,
Timeline as TimelineIcon, BubbleChart as BubbleChartIcon,KeyboardArrowRight as KeyboardArrowRightIcon,
VerifiedUser as PolicyIcon, CancelPresentation as CancelPresentationIcon, Facebook as FacebookIcon,
SupervisedUserCircle as SupervisedUserCircleIcon, ContactSupport as LiveHelpIcon,
Receipt as ReceiptIcon, Storage as StorageIcon, VideoCall as VideoCallIcon, InsertLink as InsertLinkIcon,
People as PeopleIcon, HighlightOff as HighlightOffIcon} from '@material-ui/icons';
import DataServices from '../../../../../../service/data-services';
import Configuration from '../../../../../../service/configuration';
import Resources from '../../../../../../service/resources';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next'
import { Inventory as InventoryIcon, PointOfSale as PointOfSaleIcon, ProductionQuantityLimits as ProductionQuantityLimitsIcon } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {margin:0,padding:0},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },  
  itemNested: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(4)
  },
  button: {
    color: colors.black,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  buttonHide: {
    color: theme.palette.icon,
    padding: '5px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  drawerIcon: {
    color: theme.palette.white,
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: colors.orange[600]
    }
  },
 itemNested2: {
   display: 'flex',
   paddingTop: 0,
   paddingBottom: 0,
   paddingLeft: theme.spacing(6)
 },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
  
    <RouterLink {...props} />
        
  </div>
));

const SidebarNav = props => {
  const { pages, className, open, onSidebarOpen, ...rest } = props;

    const dataService = new DataServices( );
    const config = new Configuration();
    const resources = new Resources( );
    var auth = dataService.getAuthoritiesData( );
    var userData = dataService.getCurrentUserData();
    var posPrivilege = dataService.getCurrentUserPOSPrivilege();


    const classes = useStyles();

    var t = props.lang;
    var i18n = props.i18n;

    const [openDashboard, setOpenDashboard] = React.useState(window.location.pathname.includes('organization_admin_dashboard'));

    const [openDelivery, setOpenDelivery] = React.useState(window.location.pathname.includes('delivery'));
    const handleClickDelivery = () => {
      setOpenDelivery(!openDelivery);
    };
    const [openUsers, setOpenUsers] = React.useState(window.location.pathname.includes('user_'));
    const handleClickUsers = () => {
        setOpenUsers(!openUsers);
    };
    const [openSettings, setOpenSettings] = React.useState(window.location.pathname.includes('settings') || window.location.pathname.includes('user_'));
    const handleClickSettings = () => {
        setOpenSettings(!openSettings);
    };
    const [openPos, setOpenPos] = React.useState(window.location.pathname.includes('pos_'));
    const handleClickPos = () => {
      setOpenPos(!openPos);
    };
    const [openAccounting, setOpenAccounting] = React.useState(window.location.pathname.includes('accounting_'));
    const handleClickAccounting = () => {
      setOpenAccounting(!openAccounting);
    };
    const [openAccounts, setOpenAccounts] = React.useState(window.location.pathname.includes('accounts_'));
    const handleClickAccounts = () => {
      setOpenAccounts(!openAccounts);
    };
    const [openTransaction, setOpenTransaction] = React.useState(window.location.pathname.includes('transaction_'));
    const handleClickTransaction = () => {
      setOpenTransaction(!openTransaction);
    };
    const [openFinancial, setOpenFinancial] = React.useState(window.location.pathname.includes('financial_'));
    const handleClickFinancial = () => {
      setOpenFinancial(!openFinancial);
    };

    const [openPosOrder, setOpenPosOrder] = React.useState(window.location.pathname.includes('sale'));
    const handleClickPosSale = () => {
      setOpenPosOrder(!openPosOrder);
    };
    const [openBooster, setOpenBooster] = React.useState(window.location.pathname.includes('booster'));
    const handleClickBooster = () => {
      setOpenBooster(!openBooster);
    };

    const hrmLogin = () =>{
        dataService.retrieve( config.SERVICE_NAME_USER, config.SERVICE_ACTION_USER_HR_AUTHORIZATION).then(items => {
            if( items && items.success ) {
                var anchor = document.createElement('a');
//                anchor.href = 'https://hrdemo.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.href = 'https://ghr.gigagates.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
//                anchor.href = 'https://hrtest.quickdoor2door.com/login/' + items.data.access_token +'/'+ items.data.expires_in +'/'+ items.data.refresh_token;
                anchor.target="_blank";
                anchor.click();
            }else{
                alert(items.message);
            }
        });
    }

    const getFacebookPageList = (type, condition ) =>{

        var pageData =dataService.getDefaultPageData();
        if( pageData ){
              var contentItem =pageData;

              if( type==='audiences')
                  return '/audiences_list/Facebook/' + contentItem.pageId + '/' + encodeURIComponent(contentItem.name) + '/' + encodeURIComponent(contentItem.coverPhoto? contentItem.coverPhoto : '');

              else if( type !=='audiences' && condition==='connect')
                  return '/fb_message_create/' + type + '/' + contentItem.pageId + '/' + encodeURIComponent(contentItem.name);
              else
                  return '/fb_message_histories/'+ type +'/' + contentItem.pageId + '/' + encodeURIComponent(contentItem.name) + '/' + encodeURIComponent(contentItem.coverPhoto? contentItem.coverPhoto : '') +'/' +condition;

        }else{
             return '/facebook_pages';
        }
    }

    const [openProduct, setOpenProduct] = React.useState(window.location.pathname.includes('products'));
    const handleClickProduct = () => {
        setOpenProduct(!openProduct);
    };

    const [openInventory, setOpenInventory] = React.useState(window.location.pathname.includes('inventories'));
    const handleClickInventory = () => {
        setOpenInventory(!openInventory);
    };

    const [openBroadcast, setOpenBroadcast] = React.useState(window.location.pathname.includes('broadcast_'));
    const handleClickBroadcast = () => {
      setOpenBroadcast(!openBroadcast);
    };

    const [openSubscriber, setOpenSubscriber] = React.useState(window.location.pathname.includes('subscriber_'));
    const handleClickSubscriber = () => {
      setOpenSubscriber(!openSubscriber);
    };

    const [openPackage, setOpenPackage] = React.useState(window.location.pathname.includes('package_'));
    const handleClickPackage = () => {
      setOpenPackage(!openPackage);
    };

    const [openSupportTicket, setOpenSupportTicket] = React.useState(window.location.pathname.includes('support_ticket_'));
    const handleClickSupportTicket = () => {
      setOpenSupportTicket(!openSupportTicket);
    };

    const [openBilling, setOpenBilling] = React.useState(window.location.pathname.includes('billing_'));
    const handleClickBilling = () => {
      setOpenBilling(!openBilling);
    };
    const [openSuperUsers, setOpenSuperUsers] = React.useState(window.location.pathname.includes('superuser_'));
    const handleClickSuperUsers = () => {
        setOpenSuperUsers(!openSuperUsers);
    };
    const [openMessaging, setOpenMessaging] = React.useState(window.location.pathname.includes('fb_message_'));
    const handleClickMessaging = () => {
      setOpenMessaging(!openMessaging);
    };
    const [openViberMessage, setOpenViberMessage] = React.useState(window.location.pathname.includes('viber_message_'));
    const handleClickViberMessage = () => {
      setOpenViberMessage(!openViberMessage);
    };
    const [openMobileMessage, setOpenMobileMessage] = React.useState(window.location.pathname.includes('mobile_message_'));
    const handleClickMobileMessage = () => {
      setOpenMobileMessage(!openMobileMessage);
    };

  return (
          <div>

        {/* Subscriber menu full*/}
        { (open && auth.includes('PACKAGE_SUBSCRIBER_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSubscriber();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SupervisedUserCircleIcon />}</div>
                    {'Subscribers'}
                  </Button>
                {openSubscriber? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSubscriber} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('PACKAGE_SUBSCRIBER_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/subscriber_add'} >
                            {'Add new subscriber'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_SUBSCRIBER_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/subscriber_list'} >
                            {'Subscriber list'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Subscriber menu mini*/}
        { (!open && auth.includes('PACKAGE_SUBSCRIBER_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Subscribers'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(true);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<SupervisedUserCircleIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Package menu full*/}
        { (open && auth.includes('PACKAGE_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickPackage();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                    {'Packages'}
                  </Button>
                {openPackage? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openPackage} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/*{ auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_create'} >
                            {'Add new package'}
                          </Button>
                        </ListItem>
                    }*/}
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_list'} >
                            {'Package list'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_subscribe_package'} >
                            {'Subscribe a package'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/package_purchasing_packages'} >
                            {'Package subscription list'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Package menu mini*/}
        { (!open && auth.includes('PACKAGE_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Packages'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(true);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<BusinessCenterIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Billing menu full*/}
        { (open && auth.includes('PACKAGE_PURCHASE') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickBilling();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<ReceiptIcon />}</div>
                    {'Billing'}
                  </Button>
                {openBilling? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openBilling} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {/*{ auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_new_invoices'} >
                            {'New invoice'}
                          </Button>
                        </ListItem>
                    }*/}
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Pending'} >
                            {'Pending invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Paid'} >
                            {'Paid invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Overdue'} >
                            {'Overdue invoices'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_PURCHASE') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/billing_invoice_list/Rejected'} >
                            {'Rejected invoices'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Billing menu mini*/}
        { (!open && auth.includes('PACKAGE_PURCHASE') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Billing'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(true);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<ReceiptIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Support ticket menu full*/}
        {/*{ (open && auth.includes('PACKAGE_LIST') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSupportTicket();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {'Customer Support'}
                  </Button>
                {openSupportTicket? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSupportTicket} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_posts/post'} >
                            {'New ticket'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket list'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket category'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('PACKAGE_LIST') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/booster_facebook_page_lives/live'} >
                            {'Ticket assign'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }*/}
        {/* Support ticket menu mini*/}
        {/*{ (!open && auth.includes('PACKAGE_LIST') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Customer Support'} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(true);
                      setOpenBilling(false);
                      setOpenSuperUsers(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* Super user menu full*/}
        { ( open && auth.includes('PACKAGE_SUPER_USER_LIST') && userData && userData.roleName.includes('Super') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSuperUsers();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {'Super user accounts'}
                  </Button>
                {openSuperUsers? <ExpandMore className={classes.drawerIcon} /> : <KeyboardArrowRightIcon className={classes.drawerIcon} />}
            </ListItem>

            <Collapse in={openSuperUsers} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/superuser_list/Admin'} >
                          {'Admin'}
                        </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                      <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                      to={'/superuser_list/Support'} >
                        {'Supporting'}
                      </Button>
                    </ListItem>
                    <ListItem className={classes.itemNested} disableGutters >
                        <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                        to={'/superuser_list/Sales'} >
                          {'Sales'}
                        </Button>
                    </ListItem>
                </List>
            </Collapse>
        </List>
        }
        {/* Super user menu mini*/}
        { (!open && auth.includes('USER_ACCOUNT_CREATE') && userData && userData.roleName.includes('Super') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.people')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{
                      setOpenSubscriber(false);
                      setOpenPackage(false);
                      setOpenSupportTicket(false);
                      setOpenBilling(false);
                      setOpenSuperUsers(true);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<PeopleAlt />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Admin menu full*/}
        { ( open && auth.includes('MANAGER_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/facebook_pages'}
                  >
                <div className={classes.icon}>{<FacebookIcon />}</div>
                  {'Facebook pages'}
                </Button>
              </ListItem>
          </List>
        }
        {/* Admin menu mini*/}
        { ( !open && auth.includes('MANAGER_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Facebook pages'} arrow>
                  <Button
                      activeClassName={classes.active}
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/facebook_pages'}
                  >
                    <div className={classes.icon}>{<FacebookIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Sales menu full*/}
        { ( open && userData && userData.roleName.includes('_Sales') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/sales_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {t('menu.dashboard')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Sales menu mini*/}
        { ( !open && userData && userData.roleName.includes('Sales') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/sales_dashboard'}
                  >
                  <div className={classes.icon}>{<Dashboard />}</div>
                  {''}
                </Button>
              </ListItem>
          </List>
        }

        {/* Finance menu full*/}
        { ( open && userData && userData.roleName.includes('Finance') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={'/finance_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {t('menu.dashboard')}
                </Button>
              </ListItem>
          </List>
        }
        {/* Finance menu mini*/}
        { ( !open && userData && userData.roleName.includes('Finance') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/finance_dashboard'}
                  >
                <div className={classes.icon}>{<Dashboard />}</div>
                  {''}
                </Button>
              </ListItem>
          </List>
        }

        {/* Audiences list menu full*/}
        { ( open && auth.includes('MANAGER_READ') ) &&
          <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                  <Button
                      activeClassName={classes.active}
                      className={classes.button}
                      component={CustomRouterLink}
                      to={getFacebookPageList('audiences', 'history')}
                  >
                <div className={classes.icon}>{<PeopleIcon />}</div>
                  {'Audiences'}
                </Button>
              </ListItem>
          </List>
        }
        {/* Audiences list menu mini*/}
        { ( !open && auth.includes('MANAGER_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Audiences'} arrow>
                  <Button
                      activeClassName={classes.active}
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={getFacebookPageList('audiences', 'history')}
                  >
                    <div className={classes.icon}>{<PeopleIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Message broadcasting menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickMessaging();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Messenger'} src={'/messenger.png'} />}</div>
                    {'Facebook message'}
                  </Button>
                {openMessaging? <ExpandMore /> : <KeyboardArrowRightIcon />}
            </ListItem>

            <Collapse in={openMessaging} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('Facebook', 'connect')}
                          >
                            {'Send message'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('Facebook', 'history')}
                          >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Message broadcasting menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.booster.freeBoost')} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                      onClick={event =>{
                      setOpenMessaging(true);
                      setOpenViberMessage(false);
                      setOpenMobileMessage(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Messenger'} src={'/messenger.png'} />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Viber menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickViberMessage();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Viber message'} src={'/viber.png'} />}</div>
                    {'Viber message'}
                  </Button>
                {openViberMessage? <ExpandMore /> : <KeyboardArrowRightIcon />}
            </ListItem>

            <Collapse in={openViberMessage} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('Viber', 'connect')}
                          >
                            {'Send message'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('Viber', 'history')}
                          >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Viber menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Viber messages'} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                      onClick={event =>{
                      setOpenMessaging(false);
                      setOpenViberMessage(true);
                      setOpenMobileMessage(false);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Viber message'} src={'/viber.png'} />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Mobile SMS menu full*/}
        { (open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters onClick={event =>{handleClickMobileMessage();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Mobile SMS'} src={'/sms.png'} />}</div>
                    {'Mobile SMS'}
                  </Button>
                {openMobileMessage? <ExpandMore /> : <KeyboardArrowRightIcon />}
            </ListItem>

            <Collapse in={openMobileMessage} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('SMS', 'connect')}
                          >
                            {'Send mobile SMS'}
                          </Button>
                        </ListItem>
                    }
                    { auth.includes('FACEBOOK_PAGE_LISTING') &&
                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button}
                              component={CustomRouterLink}
                              to={getFacebookPageList('SMS', 'history')}
                          >
                            {'History'}
                          </Button>
                        </ListItem>
                    }

                </List>
            </Collapse>
        </List>
        }
        {/* Mobile SMS menu mini*/}
        { (!open && auth.includes('FACEBOOK_PAGE_LISTING') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Mobile SMS'} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                      onClick={event =>{
                      setOpenMessaging(false);
                      setOpenViberMessage(false);
                      setOpenMobileMessage(true);
                      setOpenSettings(false);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<Avatar style={{width:24, height:24}} alt={'Mobile SMS'} src={'/sms.png'} />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Settings menu full*/}
        { ( open && (auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE')) ) &&
            <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters onClick={event =>{handleClickSettings();}}>
                  <Button activeClassName={classes.active} className={classes.button} >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {t('menu.settings')}
                  </Button>
                  {(openSettings || openUsers)? <ExpandMore /> : <KeyboardArrowRightIcon />}
                </ListItem>
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>

                        <ListItem className={classes.itemNested} disableGutters >
                          <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                          to={'/settings_users/branch'} >
                            {t('menu.people.user')}
                          </Button>
                        </ListItem>

                        { auth.includes('FACEBOOK_PAGE_LISTING') &&
                            <ListItem className={classes.itemNested} disableGutters >
                              <Button activeClassName={classes.active} className={classes.button} component={CustomRouterLink}
                              to={'/settings_image_list'} >
                                {t('menu.settings.mediaFiles')}
                              </Button>
                            </ListItem>
                        }

                    </List>
                </Collapse>
            </List>
        }
        {/* Settings menu mini*/}
        { (!open && (auth.includes('SETTING_LOCAL_UPDATE') || auth.includes('SETTING_GLOBAL_UPDATE')) ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.settings')} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                      onClick={event =>{
                      setOpenMessaging(false);
                      setOpenViberMessage(false);
                      setOpenMobileMessage(false);
                      setOpenSettings(true);
                      onSidebarOpen();
                      }}
                  >
                    <div className={classes.icon}>{<SettingsIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Billings & Subscriptions menu full*/}
        { ( open && auth && auth.includes('ORGANIZATION_READ') ) &&
              <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters >
                    <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/subscriptions_profile'}
                    >
                  <div className={classes.icon}>{<AttachMoneyIcon />}</div>
                    {'Billings information'}
                  </Button>
                </ListItem>
              </List>
        }
        {/* Billings & Subscriptions menu mini*/}
        { (!open && auth && auth.includes('ORGANIZATION_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Billings information'} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                        component={CustomRouterLink}
                        to={'/subscriptions_profile'}
                  >
                    <div className={classes.icon}>{<AttachMoneyIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }


        {/* Logs menu full*/}
        {/*{ ( open && auth && auth.includes('LOGS_READ') ) &&
              <List {...rest} className={clsx(classes.root, className)}>
                  <ListItem className={classes.item} disableGutters >
                      <Button
                          activeClassName={classes.active}
                          className={classes.button}
                          component={CustomRouterLink}
                          to={'/audit_logs'}
                      >
                    <div className={classes.icon}>{<EventNoteIcon />}</div>
                      {t('menu.audit_logs')}
                    </Button>
                  </ListItem>
              </List>
        }*/}
        {/* Logs menu mini*/}
        {/*{ (!open && auth && auth.includes('LOGS_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.audit_logs')} arrow>
                  <Button
                      className={classes.buttonHide}
                      component={CustomRouterLink}
                      to={'/audit_logs'}
                  >
                    <div className={classes.icon}>{<EventNoteIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }*/}

        {/* Help menu full*/}
        { ( open && auth && auth.includes('ORGANIZATION_READ') ) &&
              <List {...rest} className={clsx(classes.root, className)}>
                <ListItem className={classes.item} disableGutters >
                    <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        component={CustomRouterLink}
                        to={'/video_guides'}
                    >
                  <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {'Help & support'}
                  </Button>
                </ListItem>
              </List>
        }
        {/* Help menu mini*/}
        { (!open && auth && auth.includes('ORGANIZATION_READ') ) &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={'Help & support'} arrow>
                  <Button
                      className={classes.buttonHide}
                      activeClassName={classes.active}
                        component={CustomRouterLink}
                        to={'/video_guides'}
                  >
                    <div className={classes.icon}>{<LiveHelpIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* TOC menu full*/}
        { open &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Link
                            component="a"
                            href="/toc.html"
                            target="_blank">
                <Button
                    activeClassName={classes.active}
                    className={classes.button} >
                  <div className={classes.icon}>{<PolicyIcon />}</div>
                    {t('menu.terms_conditions')}
                </Button>
                </Link>
            </ListItem>
        </List>
        }
        {/* TOC menu mini*/}
        { !open &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.terms_conditions')} arrow>
                <Link
                    component="a"
                    href="/toc.html"
                    target="_blank">
                  <Button
                      className={classes.buttonHide}
                  >
                    <div className={classes.icon}>{<PolicyIcon />}</div>
                    {''}
                  </Button>
                </Link>
                </Tooltip>
              </ListItem>
            </List>
        }

        {/* Logout menu full*/}
        { open &&
        <List {...rest} className={clsx(classes.root, className)}>
            <ListItem className={classes.item} disableGutters >
                <Button
                    activeClassName={classes.active}
                    className={classes.button}
                    onClick={event =>{dataService.removeTokenCookie();}}
                >
              <div className={classes.icon}>{<HighlightOffIcon />}</div>
                {t('menu.logout')}
              </Button>
            </ListItem>
        </List>
        }
        {/* Logout menu mini*/}
        { !open &&
            <List {...rest} className={clsx(classes.root, className)}>
              <ListItem className={classes.item} disableGutters >
                <Tooltip title={t('menu.logout')} arrow>
                  <Button
                      className={classes.buttonHide}
                      onClick={event =>{dataService.removeTokenCookie();}}
                  >
                    <div className={classes.icon}>{<HighlightOffIcon />}</div>
                    {''}
                  </Button>
                </Tooltip>
              </ListItem>
            </List>
        }

    </div>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  pages: PropTypes.array.isRequired,
  onSidebarOpen: PropTypes.func
};

//export default SidebarNav;
export default (props) => {
    const { t, i18n } = useTranslation();
    return (<SidebarNav lang={t} i18n={i18n} props{... props} />)
}